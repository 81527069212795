@use '../abstracts/' as *;

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-size: var(--fs-med);
  font-weight: 300;
  color: var(--clr-gray);
  line-height: 1.3;

  &.noscroll {
    overflow: hidden;
  }
}

.link,
.link:visited,
.link:hover {
  text-decoration: none;
}

//Spacing
.spacing {
  padding-left: 1rem;
  padding-right: 1rem;
}

// Flexbox
.flex {
  display: flex;

  &-jc-sb {
    justify-content: space-between;
  }

  &-jc-c {
    justify-content: center;
  }

  &-ai-c {
    align-items: center;
  }
}

// Buttons
button,
.button {
  padding: 0.875rem 2.1875rem;
  background: linear-gradient(
    to left,
    var(--clr-light-yellow),
    var(--clr-mustard)
  );
  border-radius: 30px;
  border: 0;
  cursor: pointer;
  color: var(--clr-white);
  font-weight: 400;
  text-decoration: none;
  transition: all 300ms ease-in-out;

  &:hover {
    color: gray;
    transition: all 300ms ease-in-out;
    font-size: 1.1rem;
  }
}

// Visibility
.hide-for-mobile {
  // hide for tablet and mobile
  @include breakpoint-down('small') {
    display: none;
  }
}

.hide-for-desktop {
  // hide for desktop viewport widths
  @include breakpoint-up('large') {
    display: none;
  }
}

.margin-header {
  margin-top: 8rem;
}

.maxWidth {
    max-width: 800px;
  }

.banner-img {
  width: 99.2vw;
  height: auto;
  
  &__black {
    filter: drop-shadow(1px 10px 40px rgb(0, 0, 0)) saturate(110%);
  }

  &__header {
    position: absolute;
    width: 72vw;
    top: 35vh;
    left: 13vw;
    line-height: 1.5;
    font-size: var(--fs-xxxl);
    font-weight: var(--fw-big);
    color: var(--clr-white);

    @include breakpoint-down(medium) {
      top: 30vh;
      left: 17vw;
      font-size: 3rem;
    }

    @include breakpoint-down(small) {
      font-size:  2.5rem;
      top: 20vh;
      left: 8vw;
    }

    @include breakpoint-up(xlarge) {
      left: 25vw;
    }

    @include breakpoint-down(mobile) {
      top: 12vh;
      left: 12vw;
      font-size:  1.15rem;
    }

    &__colorwhite {
      color: white;
    }
  }
}

.mustard {
  color: var(--clr-mustard);
}

.link__withoutdec {
  text-decoration: none;
  color: var(--clr-black);

  &--cookie {
    color: var(--clr-gray);
    font-size: 1rem;

    &:hover {
      color: var(--clr-mustard);
    }
  }

  &:hover {
    color: var(--clr-mustard);
  }
}