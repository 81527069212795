@use '../../styles/abstracts/' as *;

.footer {
  font-size: var(--fs-sm);
  font-weight: var(--fw-regular);
  list-style: none;
  font-family: 'Poppins', sans-serif;
  
  &__link {
    color: gray;
    transition: all 300ms ease-in-out;
    
    &:hover {
      color: var(--clr-mustard);
      font-size: 1rem;
      transition: all 300ms ease-in-out;
    }
  }

  &__primary {
    &-list {
      list-style: none;
    }
  }
  
  &__secondary {
    color: gray;
    // background: linear-gradient( 60deg, var(--clr-blue-jeans) 0%, var(--clr-blue-logo) 100%);
    background-color: var(--clr-light-gray);

    &-legal {
      text-align: start;

      @include breakpoint-down('medium') {
        text-align: center;
      }
    }

    &-copyright {
      text-align: right;

      @include breakpoint-down('medium') {
        text-align: center;
      }
    }
  }
}