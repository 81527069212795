

.projects {
  &__img {
    filter: drop-shadow(1px 10px 10px rgb(96, 94, 94));
  }

  &__minHeight {
    min-height: 200px;
  }
}