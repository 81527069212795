@use "/src/styles/abstracts/" as *;

figure.snip0016 {
  color: #fff;
  position: relative;
  overflow: hidden;
  margin-left: 3.5rem;
  min-width: 360px;
  min-height: 220px;
  margin-bottom: 3rem;
  width: 80%;
  background: black;
  text-align: left;

  @include breakpoint-down(large) {
    margin-left: 3rem;
  }
  @include breakpoint-down(medium) {
    margin-left: 1rem;
  }
  @include breakpoint-down(small) {
    margin-left: 0;
    min-width: 220px;
    min-height: 160px;
  }
  @include breakpoint-down(mobile) {
    margin-left: 0;
    min-width: 110px;
    min-height: 50px;
  }
}
// figure.snip0016 * {
//   -webkit-box-sizing: border-box;
//   box-sizing: border-box;
// }
figure.snip0016 img {
  max-width: 100%;
  opacity: 1;
  width: 100%;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}
figure.snip0016 figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px 3em;
  width: 100%;
  height: 100%;
}
figure.snip0016 figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 100%;
  border-left: 4px solid rgba(255, 255, 255, 0.8);
  content: '';
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
figure.snip0016 h4,
figure.snip0016 p {
  margin: 0 0 5px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s,-webkit-transform 0.35s,-moz-transform 0.35s,-o-transform 0.35s,transform 0.35s;
}
figure.snip0016 h4 {
  word-spacing: -0.15em;
  font-weight: 300;
  text-transform: uppercase;
  -webkit-transform: translate3d(30%, 0%, 0);
  transform: translate3d(30%, 0%, 0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
figure.snip0016 h4 span {
  font-weight: 800;
}
figure.snip0016 p {
  font-weight: 200;
  -webkit-transform: translate3d(0%, 30%, 0);
  transform: translate3d(0%, 30%, 0);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
figure.snip0016 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  color: #ffffff;
}
figure.snip0016:hover img {
  opacity: 0.3;
}
figure.snip0016:hover figcaption h4 {
  opacity: 1;
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
figure.snip0016:hover figcaption p {
  opacity: 0.9;
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
figure.snip0016:hover figcaption::before {
  background: rgba(255, 255, 255, 0);
  left: 30px;
  opacity: 1;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.carouselCards {
  display: flex;
}
