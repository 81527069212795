.header {
  font-family: 'Poppins', sans-serif;

  .white {
    color: var(--clr-white);
  }

  // Hamburger Open Styles
  &.open {
    .header__toggle {
      > span:first-child {
        transform: rotate(45deg);
      }

      > span:nth-child(2) {
        opacity: 0;
      }

      > span:last-child {
        transform: rotate(-45deg);
      }
    }
  }

  .overlay {
    opacity: 0;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-image: linear-gradient(var(--clr-mustard), transparent);
    z-index: 97;
  }

  nav {
    position: relative;
    // padding-top: 1rem;
    // padding-bottom: 1rem;
    z-index: 98;
  }
  &__logo {
    img {
      // width: 150px;
      height: 70px;
      // border-radius: 50%;
    }
  }

  &__toggle {
    // Mobile Menu Toggle

    > span {
      display: block;
      width: 26px;
      height: 2px;
      background-color: var(--clr-mustard);
      transition: all 300ms ease-in-out;
      transform-origin: 1.7px 1px;

      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }

  &__menu {
    position: absolute;
    width: calc(100% - 3rem);
    left: 50%;
    transform: translateX(-50%);
    background: var(--clr-white);
    margin-top: 1.5rem;
    padding: 1.625rem;
    border-radius: 5px;
    z-index: 99;

    &-links {
      display: block;
      padding: 0.625rem;
      color: var(--clr-mustard);
      text-align: center;
    }
  }

  &__links {
    &-link {
      font-size: var(--fs-med);
      position: relative;
      color: var(--clr-gray);
      font-weight: 500;
      transition: all 300ms ease-in-out;
      left: -22%;

      &:not(:last-child) {
        margin-right: 32px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 3px;
        left: 0;
        right: 0;
        bottom: -5px;
        background: linear-gradient(
          to left,
          var(--clr-light-yellow),
          var(--clr-mustard)
        );
        width: 0%;
        transition: all 300ms ease-in-out;
      }

      &:hover {
        color: var(--clr-mustard);

        &:before {
          width: 100%;
        }
      }
    }
  }

  &__cta {
    transition: all 300ms ease-in-out;
    
    &:hover {
      transition: all 300ms ease-in-out;
      color: var(--clr-gray);
    }
  }
}

.bg-white {
  filter: drop-shadow(1px 10px 40px rgb(166, 163, 163));
}