@use "/src/styles/abstracts/" as *;

.getTouch {
  background-color: rgba($color: #000000, $alpha: 0.02);
  &__contact {
    margin-bottom: 15vh;
    &-color {
      color: var(--clr-mustard);
      font-weight: var(--fw-bold);
      @include breakpoint-down('medium') {
        padding: 1rem;
      }
    }
  }
}