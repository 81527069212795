@use "/src/styles/abstracts/" as *;

.contact {
  margin-top: 8rem;

  &__first {
    @include breakpoint-down(medium) {
      margin: 4rem 0;
    }
  }

  &__link {
    color: var(--clr-mustard);

    &:hover {
      transform: scale(1.5);
      color: var(--clr-brown);
    }
  }
}
