
.services {

  &__img {
    border-radius: 30px;
  }

  &__li {
    margin-top: 1rem;
    margin-bottom: 1rem;
    list-style-type: none;
  }
}